import React from "react"
import Title from "./Title"
import Picture from "./Picture"


export const Pictures = ({pictures,category, title}) => {
  return (
    <section className="section">
      <Title title={title} />
      <div className="section-center">
        {pictures.map((picture) => {
          return (
            <Picture key={picture.id} {...picture} />
          )
        })}
      </div>
      
    </section>
  )
}
export default Pictures
