import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
const Picture = ({id,image,category,description}) => {
  return (  
      <article>
         <Image fluid={image.childImageSharp.fluid} className="gallery-img" alt={description}/>
      </article>
  )
}
Picture.propTypes = {}

export default Picture
