import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Pictures from "../components/Pictures"
import SEO from "../components/SEO"

const Gallery = ({
  data: {
    allStrapiImages:{ nodes: pictures },
  },
}) => {
  return (
    <Layout>
      <SEO title="Gallery" description="Gallery page featuring product, event and landscape photography"/>
      <section className="gallery-page">
        <Pictures pictures={pictures} title="gallery" />
      </section>
    </Layout>
  )    
}

export const query = graphql`
  {
    allStrapiImages {
        nodes {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          id
          category
          description
        }
      }
  }
`
export default Gallery
